body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: rtl;
    text-align: right;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.5;
    color: #24292e;
    background-color: #fff;
}

.app {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}

input.error {
    border-color: red;
}

.input-feedback {
    color: red;
    height: 5px;
    margin-top: -12px;
}

.main-content {
    margin-bottom: 60px;
}

.jumbotron {
    margin-bottom: 1rem !important;
}

.page-item .form-control {
    width: 5rem;
    display: inline-block;
    padding: 4px 12px;
    height: 100%;
}

.page-item .btn {
    vertical-align: baseline;
    padding: 4px 12px;
}

.dropdown-menu {
    text-align: right;
}

h2 {
    text-align: center;
}

@media (min-width: 48em) {
    article {
        font-size: 18px;
    }
}

.whats-new h3 {
    margin-top: 2rem;
}

@media (min-width: 576px) {
    .container.instruction {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container.instruction {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container.instruction {
        max-width: 750px;
    }
}
@media (min-width: 1200px) {
    .container.instruction {
        max-width: 750px;
    }
}

.instruction ::marker {
    font-weight: bold;
}

.instruction table {
    font-size: 0.9rem;
}

.instruction .news {
    font-size: 0.9rem;
}

.form-check-inline {
    margin-left: 0.75rem;
    margin-right: 0;
}

.navbar {
    background-color: #563d7c !important;
}
